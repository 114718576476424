import React from "react";

import "./HostStageItem.css";

interface HostStageItemProps {
  text: string;
  selected: boolean;
  onClick: () => void;
}

const HostStageItem: React.FC<HostStageItemProps> = ({
  text,
  onClick,
  selected,
}) => {
  return (
    <div
      className="stage-item"
      onClick={onClick}
      style={selected ? { backgroundColor: "#f0ede0", color: "black" } : {}}
    >
      <p>{text}</p>
    </div>
  );
};

export default HostStageItem;
