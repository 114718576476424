import React, { ReactNode } from "react";
import "./Headerbar.css";

// Define the props type to include children
interface HeaderBarProps {
  children?: ReactNode; // Use ReactNode for typing children
}

const HeaderBar: React.FC<HeaderBarProps> = ({ children }) => {
  return <div className="headerbar">{children}</div>;
};

export default HeaderBar;
