const config = {
  apiKey: "AIzaSyCaUZojXIodGzbPQ-3_YSBX2oE2yzhBjow",
  authDomain: "storyglobe-b1d35.firebaseapp.com",
  projectId: "storyglobe-b1d35",
  storageBucket: "storyglobe-b1d35.appspot.com",
  messagingSenderId: "691551396616",
  appId: "1:691551396616:web:98efc6ab9e3b06ca5982bd",
  measurementId: "G-FLH6CKFNXL",
};

export function getFirebaseConfig() {
  if (!config || !config.apiKey) {
    throw new Error(
      "No Firebase configuration object provided." +
        "\n" +
        "Add your web app's configuration object to firebase-config.ts"
    );
  } else {
    return config;
  }
}
