import { ChangeEvent, FormEvent, useRef, useState } from "react";
import logoImage from "../assets/logo.png";
import { signInGoogleUser, signInUser } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import "./LoginPage.css";
import LargeButton from "../components/input/LargeButton";

const defaultFormFields = {
  email: "",
  password: "",
};

function LoginPage() {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email, password } = formFields;
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const resetFormFields = () => {
    return setFormFields(defaultFormFields);
  };
  const triggerSubmit = () => {
    submitButtonRef.current?.click();
  };
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      // Send the email and password to firebase
      const userCredential = await signInUser(email, password);

      if (userCredential) {
        resetFormFields();
        navigate("/hosts");
      }
    } catch (error: any) {
      console.log("User Sign In Failed", error.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      // Send the email and password to firebase
      const userCredential = await signInGoogleUser();

      if (userCredential) {
        resetFormFields();
        navigate("/hosts");
      }
    } catch (error: any) {
      console.log("User Sign In Failed", error.message);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <div className="LoginPage">
      <div
        style={{
          width: 319,
          marginLeft: "auto",
          marginRight: "auto",
          transform: "translateY(-80px)",
        }}
      >
        <img src={logoImage} alt="logo" width={"85%"} />
        <br />
        <br />
        <br />
        <form ref={formRef} onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            value={email}
            onChange={handleChange}
            placeholder="Email"
            style={{ width: "100%", boxSizing: "border-box", padding: 16 }}
            required
          />
          <input
            style={{
              width: "100%",
              boxSizing: "border-box",
              marginTop: 7,
              padding: 16,
            }}
            type="password"
            name="password"
            value={password}
            onChange={handleChange}
            placeholder="Password"
            required
          />
          <hr style={{ marginTop: 20, marginBottom: 28 }} />
          <LargeButton
            text={"Login"}
            icon={null}
            color={"gray"}
            fillWidth={true}
            onClick={triggerSubmit}
          ></LargeButton>{" "}
          <button type="submit" ref={submitButtonRef} style={{ opacity: 0 }}>
            Submit
          </button>
        </form>

        <LargeButton
          text={"Continue with Google"}
          icon={null}
          color={null}
          outlineOnly={true}
          fillWidth={true}
          onClick={handleGoogleLogin}
        ></LargeButton>
      </div>
    </div>
  );
}

export default LoginPage;
