import HeaderBar from "../components/layout/Headerbar";
import NavBar from "../components/layout/NavBar";

import copyIcon from "../assets/actions.png";

function ActionsPage() {
  return (
    <div className="app">
      <HeaderBar>
        <img
          src={copyIcon}
          alt="hosts"
          height={32}
          style={{ display: "inline-block", marginLeft: 20, marginTop: 25 }}
        />
        <h1
          style={{
            display: "inline-block",
            marginLeft: 8,
            verticalAlign: "top",
            paddingTop: 13.5,
            fontWeight: 600,
          }}
        >
          Actions
        </h1>
        <div
          style={{
            display: "inline-flex",
            right: 0,
            position: "absolute",
            boxSizing: "border-box",
            padding: 15,
          }}
        ></div>
      </HeaderBar>
      <NavBar selectedIndex={1} />
      <div className="content">
        <p
          style={{
            marginLeft: 4,
            paddingBottom: 28,
            maxWidth: 600,
            fontSize: 18,
            paddingLeft: 16,
          }}
        >
          This page is still under construction.
        </p>
      </div>
    </div>
  );
}
export default ActionsPage;
