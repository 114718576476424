import React, { useState } from "react";

interface ImagePickerProps {
  onValidImageSelected: (file: File) => void;
}

const ImagePicker: React.FC<ImagePickerProps> = ({ onValidImageSelected }) => {
  const [validationMessage, setValidationMessage] = useState<string>("");

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          if (!["image/jpeg", "image/png"].includes(file.type)) {
            setValidationMessage("Only PNG and JPG formats are supported.");
          } else if (img.width > 200 && img.height > 200) {
            onValidImageSelected(file);
          } else {
            setValidationMessage("Image dimensions must be larger than 200px.");
          }
        };

        if (e.target?.result) {
          img.src = e.target.result.toString();
        }
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <input type="file" accept="image/*" onChange={handleImageChange} />
      <p style={{ color: "red" }}>{validationMessage}</p>
    </div>
  );
};

export default ImagePicker;
