import HeaderBar from "../components/layout/Headerbar";
import NavBar from "../components/layout/NavBar";
import actions from "../assets/actions.png";
import { useApiReports } from "../api/api";
import "./ReportsPage.css";

function ReportsPage() {
  const reports = useApiReports();

  return (
    <div className="app">
      <HeaderBar>
        <img
          src={actions}
          alt="Reports"
          height={32}
          style={{ display: "inline-block", marginLeft: 20, marginTop: 25 }}
        />
        <h1
          style={{
            display: "inline-block",
            marginLeft: 8,
            verticalAlign: "top",
            paddingTop: 13.5,
            fontWeight: 600,
          }}
        >
          Reports
        </h1>
        <div
          style={{
            display: "inline-flex",
            right: 0,
            position: "absolute",
            boxSizing: "border-box",
            padding: 15,
          }}
        ></div>
      </HeaderBar>
      <NavBar selectedIndex={1} />

      <div className="content" style={{ paddingLeft: 16, paddingRight: 16 }}>
        <table>
          <thead>
            <tr>
              <th>Entity</th>
              <th>Reason</th>
              <th>User</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {(reports.data || []).map((report) => (
              <tr key={report.entityId}>
                <td>{report.entityType} (ID: {report.entityId})</td>
                <td>{report.reason}</td>
                <td>{report.userProfile.username}</td>
                <td>{new Date(report.createdAt).toLocaleString()}</td>
                <td>{report.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default ReportsPage;
