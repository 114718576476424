import React, { useState } from "react";

interface AudioPickerProps {
  onValidAudioSelected: (file: File) => void;
}

const AudioPicker: React.FC<AudioPickerProps> = ({ onValidAudioSelected }) => {
  const [validationMessage, setValidationMessage] = useState<string>("");

  const handleAudioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (file) {
      if (file.type !== "audio/mpeg") {
        setValidationMessage("Only MP3 format is supported.");
      } else {
        onValidAudioSelected(file);
      }
    }
  };

  return (
    <div>
      <input type="file" accept="audio/mp3" onChange={handleAudioChange} />
      <p style={{ color: "red" }}>{validationMessage}</p>
    </div>
  );
};

export default AudioPicker;
