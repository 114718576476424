import React from "react";
import "./GridItem.css";
import copyIcon from "../../assets/copy.png";
import SmallButton from "../input/SmallButton";
import { PersonaModel } from "../../model/persona";
import { useNavigate } from "react-router-dom";
import { apiClonePersona } from "../../api/api";
import eventBus from "../../event-bus";

interface GridItemProps {
  persona: PersonaModel;
}

const GridItem: React.FC<GridItemProps> = ({ persona }) => {
  const navigate = useNavigate();
  const moveForward = () => {
    eventBus.emit("MOVE_FORWARD", persona.id);
  };
  const moveBack = () => {
    eventBus.emit("MOVE_BACK", persona.id);
  };
  return (
    <div className="grid-item">
      <div className="host-image">
        <img
          src={persona.pictureUrl}
          alt="persona"
          height={"100%"}
          width={"100%"}
        />
      </div>
      <h1
        title={persona.name}
        style={{
          maxLines: 1,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {persona.name}
      </h1>
      {persona.stage === "prod" && <p style={{ color: "#34A853" }}>•Live</p>}
      {(persona.stage == null || persona.stage === "draft") && (
        <p style={{ color: "red" }}>•Draft</p>
      )}
      {persona.stage === "staging" && <p style={{ color: "red" }}>•Testing</p>}
      <div>
        <SmallButton
          icon={null}
          text="Edit"
          isOutlineOnly={false}
          onClick={() => {
            navigate("/hosts/" + persona.id);
          }}
        />
        <SmallButton
          icon={copyIcon}
          text="Clone"
          isOutlineOnly={true}
          onClick={() => {
            apiClonePersona(persona.id).then((response) => {
              navigate("/hosts/" + response.data!.id);
            });
          }}
        />
      </div>
      <button className="arrange-button" style={{ left: 0 }} onClick={moveBack}>
        {"<"}
      </button>
      <button
        className="arrange-button"
        style={{ right: 0 }}
        onClick={moveForward}
      >
        {">"}
      </button>
    </div>
  );
};

export default GridItem;
