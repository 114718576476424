import React from "react";
import GridItem from "./GridItem";
import "./ResizableGrid.css";

interface ResizableGridProps {
  items: any[];
}

const ResizableGrid: React.FC<ResizableGridProps> = ({ items }) => {
  return (
    <div className="resizable-grid">
      {items.map((item, index) => (
        <GridItem key={index} persona={item} />
      ))}
    </div>
  );
};

export default ResizableGrid;
