import React from "react";

import "./LargeButton.css";

interface LargeButtonProps {
  text: string;
  icon: string | null;
  color: string | null;
  outlineOnly?: boolean;
  fillWidth?: boolean;
  onClick: () => void;
}

const LargeButton: React.FC<LargeButtonProps> = ({
  text,
  onClick,
  icon,
  color,
  outlineOnly = false,
  fillWidth = false,
}) => {
  return (
    <button
      className={
        (outlineOnly ? "large-button-outline" : "large-button") +
        " " +
        (fillWidth ? "large-button-fillwidth" : "")
      }
      onClick={onClick}
      style={color != null && !outlineOnly ? { backgroundColor: color } : {}}
    >
      {icon != null && <img src={icon} alt="Icon" className="button-icon" />}
      {text}
    </button>
  );
};

export default LargeButton;
